import { sectionStyle } from 'holded/lib/styles';

export const defaultThemeValues = {
  background: '',
  quoteMarkColor1: '',
  quoteMarkColor2: '',
  quoteColor: '',
  nameColor: '',
  workTitleColor1: '',
  workTitleColor2: '',
  headerTextColor: '',
  descriptionTextColor: '',
};

export const TestimonialTheme: TestimonialThemeType = {
  testimonial: {
    background: {
      soft: sectionStyle.background.soft,
      dark: sectionStyle.background.dark,
    },
    quoteColor: {
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    headerTextColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    descriptionTextColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.soft,
    },
    quoteMarkColor1: {
      soft: sectionStyle.textColor.blueSoft,
      dark: sectionStyle.textColor.blueStrong,
    },
    quoteMarkColor2: {
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.blueSoft,
    },
    nameColor: {
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    workTitleColor1: {
      soft: sectionStyle.textColor.grayMedium,
      dark: sectionStyle.textColor.blueSoft,
    },
    workTitleColor2: {
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.blueSoft,
    },
  },
};

export type TestimonialThemeType = {
  testimonial: {
    background: {
      soft: string;
      dark: string;
    };
    quoteColor: {
      soft: string;
      dark: string;
    };
    headerTextColor: {
      light: string;
      soft: string;
      dark: string;
    };
    descriptionTextColor: {
      light: string;
      soft: string;
      dark: string;
    };
    quoteMarkColor1: {
      soft: string;
      dark: string;
    };
    quoteMarkColor2: {
      soft: string;
      dark: string;
    };
    nameColor: {
      soft: string;
      dark: string;
    };
    workTitleColor1: {
      soft: string;
      dark: string;
    };
    workTitleColor2: {
      soft: string;
      dark: string;
    };
  };
};
