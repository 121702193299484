import { useEffect, useState } from 'react';

import { useLandingContext } from 'holded/lib/context/landingContext';
import { getThemeStyles } from 'holded/lib/styles';
import { TrustpilotLineSection } from 'holded/modules/cms/domain/components/testimonial';
import { defaultThemeValues, TestimonialTheme } from 'holded/modules/cms/ui/sections/testimonials/TestimonialTheme';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';
const TrustpilotLine = ({
  mainRatingText,
  ratedText,
  basedOnText,
  onText,
  stars,
  trustpilotUrl,
  trustpilotLogo,
  background,
  outOfTotalText,
  reviewsText,
  justifyText,
}: TrustpilotLineSection) => {
  const {
    globalData: { global },
  } = useLandingContext();
  const [themeValues, setThemeValues] = useState(defaultThemeValues);
  const position =
    justifyText === 'center'
      ? 'justify-center'
      : justifyText === 'left'
      ? 'justify-center lg:justify-start'
      : justifyText === 'right'
      ? 'lg:justify-end'
      : 'lg:justify-start';

  useEffect(() => {
    const themeStyles = getThemeStyles(TestimonialTheme.testimonial, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div className={`${themeValues.background}`}>
      <div className={`${position} max-w-lg mx-auto mt-10`}>
        <a href={trustpilotUrl} target={'_blank'} rel={'noreferrer'} className={'cursor-pointer'}>
          <div className={`${themeValues.headerTextColor} flex ${position} items-center`}>
            <p className={`font-semibold text-b1 mr-4 ${themeValues.descriptionTextColor}`}>{mainRatingText}</p>
            {stars?.data && <NextImage media={stars.data} classImg={'object-cover w-1/3'} />}
          </div>
          <div className={`flex ${position} justify-center items-center mt-2 ${themeValues.descriptionTextColor}`}>
            <p className={`text-center text-sm md:text-b3`}>
              {ratedText}
              <span className="ml-1 font-bold">{global?.attributes?.rating?.value}</span> / {outOfTotalText}{' '}
              {basedOnText}
              <span className="font-bold ml-1">{global?.attributes?.rating?.count}</span>
              <span className="font-bold ml-1">{reviewsText} </span>
              {onText}
            </p>
            {trustpilotLogo && (
              <div className="ml-2">
                <NextImage media={trustpilotLogo?.data} classImg={'object-cover'} width={80} height={30} />
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  );
};

export default TrustpilotLine;
